import { createSlice } from "@reduxjs/toolkit";
import API from "../conection";

const initialState = {
  reportList: [],
  pdfReport: false,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReportList: (state, action) => {
      state.reportList = action.payload;
    },
    setPdfReport: (state, action) => {
      state.pdfReport = action.payload;
    },
  },
});

export const getReportList =
  (fechaI, fechaO, accessToken) => async (dispatch) => {
    try {
      fechaI = new Date(fechaI).toISOString();
      fechaO = new Date(fechaO).toISOString();
      const list = await API.get(
        `/registro/listar-registros/?fechaI=${fechaI}&&fechaO=${fechaO}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const res = await dispatch(setReportList(list.data));
      !list.data.length
        ? dispatch(setPdfReport(true))
        : dispatch(setPdfReport(false));
    } catch (e) {
      console.log(e);
    }
  };

export const changePdfReport = (value) => async (dispatch) => {
  await dispatch(setPdfReport(value));
};

export const { setReportList, setPdfReport } = reportSlice.actions;
export default reportSlice.reducer;
