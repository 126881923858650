import {
  ArrowBack,
  Image,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { Box } from "@mui/system";
import { Form, FormikProvider, useFormik, validateYupSchema } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { registerManualAsync } from "../store/LoginSlice";

function RegisterManual() {
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const [imagenView, setViewImagen] = useState(null);
  const [imagenFile, setImagenFile] = useState(null);

  const handleChangeImagen = (e) => {
    console.log(e.target.files);
    setImagenFile(e.target.files);
    setViewImagen(URL.createObjectURL(e.target.files[0]));
  };
  const schema = Yup.object().shape({
    nombres: Yup.string().required("Nombres son requeridos"),
    apellidos: Yup.string().required("Apellidos son requeridos"),
    email: Yup.string()
      .email("Correo no valido")
      .required("Correo es requerido"),
    password: Yup.string()
      .required("Contraseña es requerido")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.)[A-Za-z\d]{8,}$/,
        "Debe contener almenos 8 Caracteres, 1 mayuscula, 1 minuscula, 1 numero"
      ),
    confirm: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Las contraseñas deben ser iguales"
    ),
  });
  const validateDominio = (values) => {
    let errores = {};
    const email = values.email;
    let result = [];
    if (email) {
      result = email.split("@");
    }
    if (
      result[1] !== "est.umss.edu" &&
      result[1] !== "umss.edu" &&
      result[1] !== "umss.edu.bo"
    ) {
      errores.email =
        "El dominio no esta permitido, intente con el dominio est.umss.edu";
    }
    return errores;
  };
  const formik = useFormik({
    initialValues: {
      imagen: null,
      nombres: "",
      apellidos: "",
      email: "",
      password: "",
      confirm: "",
    },
    validate: validateDominio,
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      dispatch(registerManualAsync(values, imagenFile));
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  return (
    <Container
      maxWidth="sm"
      sx={{ height: "99vh", alignItems: "center", mt: 20, mb: 20 }}
    >
      {" "}
      <Paper sx={{ p: 2, borderRadius: 5 }}>
        <Button
          component={RouterLink}
          to="/"
          startIcon={<ArrowBack></ArrowBack>}
        >
          Inicio
        </Button>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4">Registro </Typography>
          <Typography>Ingrese sus datos</Typography>
        </Box>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Box>
                <label htmlFor="contained-button-file">
                  <TextField
                    type="file"
                    multiple
                    fullWidth
                    required
                    accept="image/*"
                    id="contained-button-file"
                    onChange={handleChangeImagen}
                    hidden
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Avatar src={imagenView} alt="image"></Avatar>
                        </InputAdornment>
                      ),
                    }}
                    // sx={{ display: "none" }}
                  />
                </label>
              </Box>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  sx={{ borderRadius: 2 }}
                  fullWidth
                  label="Nombres"
                  {...getFieldProps("nombres")}
                  error={Boolean(touched.nombres && errors.nombres)}
                  helperText={touched.nombres && errors.nombres}
                />

                <TextField
                  fullWidth
                  label="Apellidos"
                  {...getFieldProps("apellidos")}
                  error={Boolean(touched.apellidos && errors.apellidos)}
                  helperText={touched.apellidos && errors.apellidos}
                />
              </Stack>
              <TextField
                fullWidth
                autoComplete="email"
                margin="normal"
                label="Correo Institucional"
                type="email"
                variant="outlined"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Contraseña"
                variant="outlined"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...getFieldProps("password")}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextField
                sx={{ borderRadius: 2 }}
                fullWidth
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                label="Confirmar contraseña"
                {...getFieldProps("confirm")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.confirm && errors.confirm)}
                helperText={touched.confirm && errors.confirm}
              />
              <Box sx={{ py: 2, position: "relative" }}>
                <Button
                  color="primary"
                  /* 						disabled={formik.isSubmitting} */
                  fullWidth
                  size="large"
                  type="submit"
                  disabled={isLoading}
                  variant="contained"
                >
                  Registrarse
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Typography color="textPrimary">Ya tiene una cuenta?</Typography>
              <Link
                // underline="none"
                // variant="subtitle1"
                to="/login"
                component={RouterLink}
              >
                Iniciar Sesion
              </Link>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </Container>
  );
}

export default RegisterManual;
