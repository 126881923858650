import {
  Container,
  Typography,
  Button,
  Snackbar,
  CircularProgress,
  Paper,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { postLoginAsync } from "../store/LoginSlice";

import { Link, useNavigate } from "react-router-dom";
import { Google } from "@mui/icons-material";

export default function LoginPage() {
  const Navigate = useNavigate();

  const { isAuth, isLoading, accessToken } = useSelector(
    (state) => state.login
  );
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (isAuth) {
      setOpen(true);
    }
  }, [isAuth]);

  const dispatch = useDispatch();

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const onLoginSuccess = (res) => {
    // console.log(res.getAuthResponse().id_token);
    submitUser(res.profileObj);
  };

  const onLoginFailure = (res) => {
    console.log("Login Failed-google:", res);
  };

  /*   const convertImage = (url) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      //console.log(dataURL);
      return dataURL;
    };
    img.src = url;
  }; */
  function submitUser(profile) {
    /*     const imageb64 = convertImage(profile.imageUrl); */
    const fullName = profile.givenName + " " + profile.familyName;
    let data = new FormData();
    data.append("last_name", profile.familyName);
    data.append("first_name", profile.givenName);
    data.append("full_name", fullName);
    data.append("picture", profile.imageUrl);
    data.append("username", profile.email);
    data.append("email", profile.email);
    data.append("network", "Google");
    dispatch(postLoginAsync(data, accessToken));
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    Navigate("/reg-task");
  };

  return (
    <Box componenet="main">
      <Container
        maxWidth="sm"
        sx={{
          height: "99vh",
          display: "flex",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Paper
          sx={{
            borderRadius: 3,
            bgcolor: "background.paper",
            p: 2,
          }}
        >
          <Box sx={{ my: 2, width: "100%", textAlign: "center" }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "primary.main" }}
            >
              BIENVENIDO AL REGISTRO DE ACTIVIDADES{" "}
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "secondary.main" }}
            >
              SAN SIMÓN
            </Typography>
          </Box>

          {isLoading && (
            <Box sx={{ width: "100%" }}>
              <CircularProgress />
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              height: 250,
            }}
          >
            <Box
              component="img"
              src="/img/logo-SSS.png"
              alt="logo"
              sx={{
                width: "auto",
                height: "100%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </Box>
          <Box sx={{ my: 2, width: "100%", textAlign: "center" }}>
            <Typography variant="h5">
              Inicia sesion con tu cuenta institucional{" "}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <GoogleLogin
              clientId={clientId}
              buttonText="Sign In"
              onSuccess={onLoginSuccess}
              onFailure={onLoginFailure}
              cookiePolicy={"single_host_origin"}
              isSignedIn={true}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  fullWidth
                  variant="contained"
                  startIcon={<Google color="white" />}
                  size="large"
                >
                  Iniciar Sesion con Google
                </Button>
              )}
            />
          </Box>
          <Stack justifyContent="flex-end" alignItems="end" sx={{ mt: 2 }}>
            <Link
              // underline="none"
              // variant="subtitle1"
              to="/login"
            >
              <Typography variant="body2">Iniciar Sesion</Typography>
            </Link>
          </Stack>
          {/*           <Box>
            <Button
              onClick={() => dispatch(postLoginAsync())}
              fullWidth
              color="primary"
              size="large"
              variant="contained"
            >
              Auth
            </Button>
          </Box>*/}
          <Snackbar
            open={open}
            autoHideDuration={3000}
            message="Inicio de sesion exitoso"
            color="success"
            onClose={handleClose}
          />
        </Paper>
      </Container>
    </Box>
  );
}
