import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LanguageIcon from "@mui/icons-material/Language";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

const UserTaskList = () => {
  const userTaskList = useSelector((state) => state.userTask.userTaskList);

  const redirectToUrl = (url) => {
    window.open(url, "_blank");
  };

  return (
    <List sx={{ width: "100%", maxWidth: 700, bgcolor: "background.paper" }}>
      {userTaskList.map((row) => (
        <ListItem key={row.id_tarea} style={{ marginBottom: "30p" }}>
          <ListItemText primary={row.detalle} secondary={row.creacion} />
          {row.urlD !== null ? (
            <Tooltip title="Ver Archivo">
              <ListItemAvatar>
                <IconButton onClick={() => redirectToUrl(row.urlD)}>
                  <InsertDriveFileIcon />
                </IconButton>
              </ListItemAvatar>
            </Tooltip>
          ) : null}
          {row.urlT !== "" ? (
            <Tooltip title="Ver Referencia">
              <ListItemAvatar>
                <IconButton onClick={() => redirectToUrl(row.urlT)}>
                  <LanguageIcon />
                </IconButton>
              </ListItemAvatar>
            </Tooltip>
          ) : null}
        </ListItem>
      ))}
    </List>
  );
};

export default UserTaskList;
