import { ArrowBack, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { Box } from "@mui/system";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { loginManualAsync } from "../store/LoginSlice";
import * as Yup from "yup";

function LoginManual() {
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading, authFailed } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const validateDominio = (values) => {
    let errores = {};
    const email = values.email;
    let result = [];
    if (email) {
      result = email.split("@");
    }
    if (
      result[1] !== "est.umss.edu" &&
      result[1] !== "umss.edu" &&
      result[1] !== "umss.edu.bo"
    ) {
      errores.email =
        "El dominio no esta permitido, intente con el dominio est.umss.edu";
    }
    return errores;
  };
  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Correo no valido")
      .required("Correo es requerido"),
    password: Yup.string()
      .min(8, "minimo de 8 caracteres")
      .required("la contraseña es requerido"),
    // confirm: Yup.string().min(8).required("Confirme la contraseña"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: validateDominio,
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      dispatch(loginManualAsync(values));
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  return (
    <Container
      maxWidth="sm"
      sx={{ height: "99vh", alignItems: "center", mt: 20, mb: 20 }}
    >
      <Paper sx={{ p: 2, borderRadius: 5 }}>
        <Button
          component={RouterLink}
          to="/"
          startIcon={<ArrowBack></ArrowBack>}
        >
          Inicio
        </Button>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4">Iniciar Sesion </Typography>
          <Typography>Ingrese sus datos</Typography>
        </Box>
        <FormikProvider noValidate value={formik}>
          <Form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                autoComplete="username"
                margin="normal"
                label="Correo Institucional"
                type="email"
                variant="outlined"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Contraseña"
                variant="outlined"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...getFieldProps("password")}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Typography variant="caption" color="textPrimary">
                  Olvido su contraseña?
                </Typography>
                <Link
                  // underline="none"
                  variant="caption"
                  to="/forgotPassword"
                  component={RouterLink}
                >
                  Cambiar contraseña
                </Link>
              </Stack>
              {authFailed && (
                <Typography color="error" variant="caption" textAlign="center">
                  {" "}
                  Las credenciales no son correctas, vuelva a intentarlo.
                </Typography>
              )}
              <Box sx={{ py: 2, position: "relative" }}>
                <Button
                  color="primary"
                  /* 						disabled={formik.isSubmitting} */
                  fullWidth
                  size="large"
                  type="submit"
                  disabled={isLoading}
                  variant="contained"
                >
                  Iniciar Sesion
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Typography color="textPrimary">No tiene una cuenta?</Typography>
              <Link
                // underline="none"
                // variant="subtitle1"
                to="/register"
                component={RouterLink}
              >
                Registrarse
              </Link>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </Container>
  );
}

export default LoginManual;
