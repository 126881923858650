import { createSlice } from "@reduxjs/toolkit";
import API from "../conection";
const initialState = {
  isAuth: localStorage.getItem("isAuth") === "true" ? true : false || false,
  isLoading: false,
  authFailed: "",
  accessToken: localStorage.getItem("accessToken") || null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setAccessToken: (state, { payload }) => {
      localStorage.setItem("accessToken", payload);
      state.accessToken = localStorage.getItem("accessToken");
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    setLoginSuccess: (state) => {
      state.isLoading = false;
      localStorage.setItem("isAuth", true);
      state.isAuth = localStorage.getItem("isAuth") === "true" ? true : false;
      state.authFailed = "";
    },
    setLoginFailed: (state, { payload }) => {
      state.isLoading = false;
      state.authFailed = payload;
    },
    setLogout: () => {
      localStorage.clear();
      window.location.reload();
    },
  },
});

export const postLoginAsync = (dataUser, accessToken) => async (dispatch) => {
  try {
    const response = await API.post(`/api/login-user`, dataUser, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(setAccessToken(response.data.accessToken));

    dispatch(setLoginSuccess());
  } catch (err) {
    throw new Error(err);
  }
};
/* Logout */
export const postLogoutAsync = (accessToken) => async (dispatch) => {
  let res = false;
  try {
    const response = await API.get(`/usuario/logout-user`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    dispatch(setLogout());
    res = true;
  } catch (err) {
    throw new Error(err);
  }
  return res;
};

export const registerManualAsync = (valuesForm, file) => async (dispatch) => {
  dispatch(setLoading());

  const img64 = await convertb64(file);
  const data = {
    ...valuesForm,
    picture: img64,
    network: "manual",
  };
  console.log(data);
  try {
    const response = await API.post(`/api/register`, data);
    dispatch(setAccessToken(response.data.accessToken));

    dispatch(setLoginSuccess());
  } catch (err) {
    throw new Error(err);
  }
};
export const loginManualAsync = (valuesForm) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const r = await API.post("/api/login-manual", valuesForm);
    dispatch(setAccessToken(r.data.accessToken));
    dispatch(setLoginSuccess());
  } catch (err) {
    dispatch(setLoginFailed(true));
    throw new Error(err);
  }
};

export const convertb64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};
export const {
  setAccessToken,
  setLoading,
  setLoginFailed,
  setLoginSuccess,
  setLogout,
} = loginSlice.actions;
export default loginSlice.reducer;
