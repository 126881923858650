import { createSlice } from "@reduxjs/toolkit";
import API from "../conection";
const initialState = {
  taskList: [],
  taskListDelete: [],
  textDescription: "",
  textUrl: "",
  textDoc: " ",
  isUpdate: false,
  idTask: 0,
  idDoc: 0,
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTaskList: (state, action) => {
      state.taskList = action.payload;
    },
    setTaskListDelete: (state, action) => {
      state.taskListDelete = action.payload;
    },
    setTextDescription: (state, action) => {
      state.textDescription = action.payload;
    },
    setTextUrl: (state, action) => {
      state.textUrl = action.payload;
    },
    setTextDoc: (state, action) => {
      state.textDoc = action.payload;
    },
    setIsUpdate: (state, action) => {
      state.isUpdate = action.payload;
    },
    setIdTask: (state, action) => {
      state.idTask = action.payload;
    },
    setIdDoc: (state, action) => {
      state.idDoc = action.payload;
    },
  },
});

export const getListTaskDelete = (accessToken) => async (dispatch) => {
  try {
    const list = await API.get(`/tarea/listar-tareas-eliminadas`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }); //Camiabr el ID de Usuario
    dispatch(setTaskListDelete(list.data));
    return list;
  } catch (e) {
    console.log(e);
  }
};
export const getListTask = (accessToken) => async (dispatch) => {
  try {
    const list = await API.get(`/tarea/list-tasks`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(setTaskList(list.data));

    return list;
  } catch (e) {
    console.log(e);
  }
};

export const createTask =
  (description, refUrl, attachment, nameFile, id, accessToken) =>
  async (dispatch) => {
    let data = new FormData();
    data.append("id", id);
    data.append("detalle", description);
    data.append("url", refUrl);
    try {
      const response = await API.post(`/tarea/crear-tarea`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (attachment !== null) {
        const base64 = await convertBase64(attachment);
        let dataDoc = new FormData();
        dataDoc.append("base64", base64);
        dataDoc.append("name", nameFile);
        dataDoc.append("id_tarea", response.data.id);
        try {
          const res = await API.post(`/documento/upload-file`, dataDoc, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const updateTask =
  (id, description, refUrl, accessToken) => async (dispatch) => {
    let data = new FormData();
    data.append("id_tarea", id);
    data.append("detalle", description);
    data.append("url", refUrl);
    try {
      const response = await API.post(`/tarea/actualizar-tarea`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

export const registerNewFile =
  (id, attachment, nameFile, accessToken) => async (dispatch) => {
    if (attachment !== null) {
      const base64 = await convertBase64(attachment);
      let dataDoc = new FormData();
      dataDoc.append("base64", base64);
      dataDoc.append("name", nameFile);
      dataDoc.append("id_tarea", id);
      try {
        const res = await API.post(`/documento/upload-file`, dataDoc, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        return res;
      } catch (e) {
        console.log(e);
      }
    }
  };
export const deleteTask = (id, iduser, accessToken) => async (dispatch) => {
  try {
    const response = await API.get(`/tarea/eliminar-tarea/?id=${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(getListTask(accessToken));
    dispatch(getListTaskDelete(accessToken));
  } catch (e) {
    console.log(e);
  }
};

export const restoreTask = (id, iduser, accessToken) => async (dispatch) => {
  try {
    const response = await API.get(`/tarea/tarea-zombie/?id=${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(getListTask(accessToken));
    dispatch(getListTaskDelete(accessToken));
  } catch (e) {
    console.log(e);
  }
};
export const deleteFile = (id, name, accessToken) => async (dispatch) => {
  let data = new FormData();
  data.append("id_documento", id);
  data.append("name_documento", name);
  try {
    const response = await API.post(`/documento/delete-document`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const {
  setTaskList,
  setTaskListDelete,
  setTextDescription,
  setTextUrl,
  setTextDoc,
  setIsUpdate,
  setIdTask,
  setIdDoc,
} = taskSlice.actions;

export default taskSlice.reducer;
