import { ArrowBack } from '@mui/icons-material';
import { Button, Container, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { NavLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <Box sx={{ height: "90vh", display: "flex", alignItems: "center" }}>
      <Container maxWidth="sm">
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Typography variant="h4">Pagina no encontrada</Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Lo sentimos, no pudimos encontrar la página que estás buscando.
            ¿Quizás has escrito mal la URL? Asegúrese de revisar su ortografía.
          </Typography>
        </Box>
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <img
            src={"./img/notfound.svg"}
            alt="sun-xl"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <NavLink to="/" style={{ textDecoration: "none" }}>
            <Button
            variant="contained"
              disableRipple
              startIcon={
                <ArrowBack></ArrowBack>
              }
              sx={{
                my: 2,

                fontWeight: "bold",
              }}
            >
              Volver
            </Button>
          </NavLink>
        </Box>
      </Container>
    </Box>
  );
}

export default NotFound