import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import API from "../conection";
import { Link as RouterLink } from "react-router-dom";

function ForgotPassword() {
  const [isLoadingForgot, setIsLoadingForgot] = useState(false);
  const [send, setSend] = useState(false);
  const [sendFailed, setSendFailed] = useState(false);
  const validateDominio = (values) => {
    let errores = {};
    const email = values.email;
    let result = [];
    if (email) {
      result = email.split("@");
    }
    if (
      result[1] !== "est.umss.edu" &&
      result[1] !== "umss.edu" &&
      result[1] !== "umss.edu.bo"
    ) {
      errores.email =
        "El dominio no esta permitido, intente con el dominio est.umss.edu";
    }
    return errores;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Correo no valido")
        .required("Correo es requerido"),
    }),
    validate: validateDominio,
    onSubmit: (values) => {
      setIsLoadingForgot(true);
      const sendEmail = async () => {
        try {
          const { data } = await API.post("/api/contact", values);
          console.log(data);
          setIsLoadingForgot(false);
          setSend(true);
          setSendFailed(false);
        } catch (err) {
          setSendFailed(true);
          setIsLoadingForgot(false);
          throw new Error(err);
        }
      };
      sendEmail();
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  return (
    <Container maxWidth="xs" sx={{ mt: 20 }}>
      {send ? (
        <Stack spacing={2} sx={{ textAlign: "center" }} alignItems="center">
          <img
            src="emailSend.svg"
            alt="email enviado"
            style={{ width: 300, height: "auto" }}
          />
          <Typography sx={{ color: green[600], fontWeight: "bold" }}>
            Se ha enviado la instrucciones a su correo, resvise su buzon.
          </Typography>
          <Button
            component={RouterLink}
            to="/login"
            startIcon={<ArrowBack></ArrowBack>}
          >
            Volver al login
          </Button>
        </Stack>
      ) : null}

      {send === false ? (
        <>
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              Para recuperar su contraseña, ingrese el correo asociado a su
              cuenta.
            </Typography>
            <Typography variant="body2" sx={{ my: 1, color: grey[700] }}>
              Enviaremos un correo con las instrucciones para cambiar su
              contraseña
            </Typography>
          </Box>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="email"
                  margin="normal"
                  label="Correo Institucional"
                  type="email"
                  variant="outlined"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <Box sx={{ py: 2, position: "relative" }}>
                  <Button
                    color="primary"
                    /* 						disabled={formik.isSubmitting} */
                    fullWidth
                    size="large"
                    type="submit"
                    disabled={isLoadingForgot}
                    variant="contained"
                  >
                    Enviar Instrucciones
                  </Button>
                  {isLoadingForgot && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Stack>
            </Form>
          </FormikProvider>
        </>
      ) : null}
      {sendFailed ? (
        <Stack
          spacing={2}
          sx={{ mt: 2, textAlign: "center" }}
          alignItems="center"
        >
          <Typography sx={{ color: red[400], fontWeight: "bold" }}>
            Algo salio mal, vuelva a intentarlo.
          </Typography>
        </Stack>
      ) : null}
    </Container>
  );
}

export default ForgotPassword;
