import { AppBar } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTheme } from "@emotion/react";

const Footer = () => {
  const theme = useTheme();
  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{ top: "auto", bottom: 0 }}
      elevation={0}
    >
      <Box
        sx={{
          width: "100%",
          height: 20,
          display: "flex",

          alignItems: "end",
        }}
      >
        <Box
          sx={{
            width: "10%",
            height: "15%",
            bgcolor: theme.palette.primary.main,
          }}
        ></Box>
        <Box
          sx={{
            width: "10%",
            height: "20%",
            bgcolor: theme.palette.secondary.main,
          }}
        ></Box>

        <Box
          sx={{
            width: "10%",
            height: "30%",
            bgcolor: theme.palette.primary.main,
          }}
        ></Box>
        <Box
          sx={{
            width: "10%",
            height: "40%",
            bgcolor: theme.palette.secondary.main,
          }}
        ></Box>
        <Box
          sx={{
            width: "10%",
            height: "50%",
            bgcolor: theme.palette.primary.main,
          }}
        ></Box>
        <Box
          sx={{
            width: "10%",
            height: "60%",
            bgcolor: theme.palette.secondary.main,
          }}
        ></Box>
        <Box
          sx={{
            width: "10%",
            height: "70%",
            bgcolor: theme.palette.primary.main,
          }}
        ></Box>
        <Box
          sx={{
            width: "10%",
            height: "80%",
            bgcolor: theme.palette.secondary.main,
          }}
        ></Box>
        <Box
          sx={{
            width: "10%",
            height: "90%",
            bgcolor: theme.palette.primary.main,
          }}
        ></Box>
        <Box
          sx={{
            width: "10%",
            height: "100%",
            bgcolor: theme.palette.secondary.main,
          }}
        ></Box>
      </Box>
    </AppBar>
  );
};

export default Footer;
