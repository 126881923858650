import { ArrowBack } from "@mui/icons-material";
import { Button, Container, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import FormResetPassword from "../components/FormResetPassword";

function ResetPassword() {
  return (
    <Container maxWidth="sm" sx={{ mt: 20, mb: 10 }}>
      <Button
        component={Link}
        to="/reg-task"
        startIcon={<ArrowBack></ArrowBack>}
      >
        Volver
      </Button>
      <Stack spacing={2}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6">
            Introduzca una nueva contraseña segura
          </Typography>
          <Typography variant="body2" sx={{ color: grey[700] }}>
            Debe contener al menos 8 caracteres con 1 mayuscula, 1 minuscula y 1
            numero como minimo
          </Typography>
        </Box>
        <FormResetPassword />
      </Stack>
    </Container>
  );
}

export default ResetPassword;
