import * as React from "react";
//import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import "./../pages/TaskPage.css";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Stack from "@mui/material/Stack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  createTask,
  getListTask,
  setTextDescription,
  setTextUrl,
  updateTask,
  setIsUpdate,
  setTextDoc,
  deleteFile,
  registerNewFile,
} from "../store/TaskSlice";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Tooltip } from "@mui/material";

export default function FormTask(props) {
  const textDescription = useSelector((state) => state.tasks.textDescription);
  const textUrl = useSelector((state) => state.tasks.textUrl);
  const textDoc = useSelector((state) => state.tasks.textDoc);
  const isUpdate = useSelector((state) => state.tasks.isUpdate);
  const idTask = useSelector((state) => state.tasks.idTask);
  const idDoc = useSelector((state) => state.tasks.idDoc);
  const user = useSelector((state) => state.user.user);

  const [attachment, setAttachment] = React.useState(null);
  const [nameFile, setNameFile] = React.useState(null);

  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.login.accessToken);

  const [leyendaDes, setLeyendaDes] = React.useState("");
  const [textErrorDes, setTextErrorDes] = React.useState(false);

  const [leyendaUrl, setLeyendaUrl] = React.useState("");
  const [textErrorUrl, setTextErrorUrl] = React.useState(false);

  function campoRequerido() {
    if (textDescription.length === 0) {
      setTextErrorDes(true);
      setLeyendaDes("La descripción es Requerido");
    } else {
      setTextErrorDes(false);
      setLeyendaDes("");
    }
  }

  function salveTask() {
    if (textErrorDes !== true) {
      registerTask();
    }
  }

  function registerTask() {
    if (isUpdate) {
      dispatch(updateTask(idTask, textDescription, textUrl, accessToken));
      if (nameFile !== textDoc) {
        const res = dispatch(
          registerNewFile(idTask, attachment, nameFile, accessToken)
        );
        res.then(() => dispatch(getListTask(accessToken)));
      }
      dispatch(setTextDescription(""));
      dispatch(setTextUrl(""));
      dispatch(setIsUpdate(false));
      //dispatch(getListTask(user.id));

      setNameFile(null);
      setAttachment(null);
      dispatch(setTextDoc(" "));
    } else {
      if (textErrorDes === true) {
        setTextErrorDes(true);
        setLeyendaDes("La descripcion debe contener máximo 255 caracteres");
      } else {
        const res = dispatch(
          createTask(
            textDescription,
            textUrl,
            attachment,
            nameFile,
            user.id,
            accessToken
          )
        );
        res.then(() => {
          dispatch(setTextDescription(""));
          dispatch(setTextUrl(""));
          dispatch(getListTask(accessToken));
          clearFile();
        });
      }
      campoRequerido();
    }
  }

  const Input = styled("input")({
    display: "none",
  });

  function attachmentInput(e) {
    setAttachment(e.target.files[0]);
    setNameFile(e.target.value.split(/(\\|\/)/g).pop());
  }

  function clearFile() {
    if (isUpdate) {
      dispatch(deleteFile(idDoc, textDoc, accessToken));
    }
    setNameFile(null);
    setAttachment(null);
    dispatch(setTextDoc(" "));
  }

  function BtnUpFile() {
    if (attachment !== null || textDoc !== " ") {
      return (
        <Tooltip title="Borrar Archivo">
          <IconButton
            onClick={clearFile}
            color="error"
            size="small"
            sx={{
              height: "40px",
              size: "small",
              borderRadius: "50%",
              background: "error.delete",
            }}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      );
    } else {
      return <div></div>;
    }
  }

  return (
    <Paper
      component="form"
      elevation={1}
      sx={{
        width: "100%",

        bgcolor: "background.paper",
        alignItems: "center",
      }}
      noValidate
      autoComplete="off"
    >
      <div className="contentForm">
        <FormControl id="formulario" fullWidth sx={{ m: 1, maxWidth: "98%" }}>
          <TextField
            id="DescriptionTask"
            sx={{ mb: 2 }}
            onChange={(e) => {
              dispatch(setTextDescription(e.target.value));
              if (textDescription.length < 10) {
                setTextErrorDes(true);
                setLeyendaDes(
                  "La descripción debe tener al menos 10 caracteres"
                );
              } else {
                if (textDescription.length > 255) {
                  setTextErrorDes(true);
                  setLeyendaDes(
                    "La descripcion debe contener máximo 255 caracteres"
                  );
                } else {
                  setTextErrorDes(false);
                  setLeyendaDes("");
                }
              }
            }}
            required
            value={textDescription}
            error={textErrorDes}
            helperText={leyendaDes}
            multiline
            rows={2}
            label="Descripción de la Tarea"
          />
          <TextField
            id="RefUrl"
            size="small"
            sx={{ mb: 2 }}
            onChange={(e) => {
              dispatch(setTextUrl(e.target.value));
              if (textUrl.length > 255) {
                setTextErrorUrl(true);
                setLeyendaUrl(
                  "La Referencia URL debe contener máximo 255 caracteres"
                );
              } else {
                setTextErrorUrl(false);
                setLeyendaUrl("");
              }
            }}
            value={textUrl}
            error={textErrorUrl}
            helperText={leyendaUrl}
            label="Referencia URL"
          />

          <Stack direction="row" spacing={4}>
            <TextField
              id="RefUrl"
              size="small"
              sx={{ mb: 2 }}
              fullWidth
              value={nameFile !== null ? nameFile : isUpdate ? textDoc : " "}
              disabled
              label="Archivo Adjunto"
            />
            <BtnUpFile />

            <label htmlFor="icon-button-file">
              <Input
                accept="image/*,.pdf,.doc,.png,.jpg"
                id="icon-button-file"
                type="file"
                onChange={(e) => attachmentInput(e)}
              />
              <Tooltip title="Subir Archivo">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  sx={{
                    background: "#D4EEED",
                  }}
                >
                  <FileUploadIcon />
                </IconButton>
              </Tooltip>
            </label>
          </Stack>
          <div className="btn-register">
            <Button onClick={salveTask} variant="contained" size="medium">
              Guardar
            </Button>
          </div>
        </FormControl>
      </div>
    </Paper>
  );
}
