import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import TaskPage from "./pages/TaskPage";
import ReportPage from "./pages/ReportPage";
import ReportTaskPage from "./pages/ReportTaskPage";
import { Provider } from "react-redux";
import store from "./store";

import NavBar from "./components/NavBar";
import { useSelector } from "react-redux";

import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { lightTheme } from "./theme";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFound";
import LoginManual from "./pages/LoginManual";
import RegisterManual from "./pages/RegisterManual";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

function App() {
  const isAuth = useSelector((state) => state.login.isAuth);
  const rolUser = useSelector((state) => state.user.rolUser);
  const isAdmin = rolUser === "admin";

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline></CssBaseline>
          {isAuth && <NavBar></NavBar>}
          <Routes>
            <Route
              path="/"
              element={
                isAuth ? <Navigate to="/reg-task" replace /> : <LoginPage />
              }
            />
            <Route
              path="/login"
              element={
                isAuth ? <Navigate to="/reg-task" replace /> : <LoginManual />
              }
            />
            <Route
              path="/register"
              element={
                isAuth ? (
                  <Navigate to="/reg-task" replace />
                ) : (
                  <RegisterManual />
                )
              }
            />
            <Route
              path="/forgotPassword"
              element={
                isAuth ? (
                  <Navigate to="/reg-task" replace />
                ) : (
                  <ForgotPassword />
                )
              }
            />

            <Route
              path="/reg-task"
              element={
                isAuth ? <TaskPage /> : <Navigate to="/" replace></Navigate>
              }
            />
            <Route
              path="/report"
              element={
                isAuth && isAdmin ? (
                  <ReportPage />
                ) : (
                  <Navigate to="/" replace></Navigate>
                )
              }
            />
            <Route
              path="/resetPassword"
              element={
                isAuth ? (
                  <ResetPassword />
                ) : (
                  <Navigate to="/" replace></Navigate>
                )
              }
            />

            <Route
              path="/user-task-list"
              element={
                isAuth ? (
                  <ReportTaskPage />
                ) : (
                  <Navigate to="/" replace></Navigate>
                )
              }
            />
            <Route path="/*" element={<NotFound />} />
            <Route path="/notfound" element={<NotFound />} />
          </Routes>
          <Footer />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
