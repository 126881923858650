/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTask,
  getListTask,
  getListTaskDelete,
  restoreTask,
  setTextDescription,
  setTextUrl,
  setTextDoc,
  setIsUpdate,
  setIdTask,
  setIdDoc,
} from "../store/TaskSlice";
import { Paper, Tooltip } from "@mui/material";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import CloseIcon from "@mui/icons-material/Close";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function Task(props) {
  const [verDeleteTask, setVerDeleteTask] = useState(false);

  const taskList = useSelector((state) => state.tasks.taskList);
  const taskListDelete = useSelector((state) => state.tasks.taskListDelete);
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.login.accessToken);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListTask(accessToken));
    dispatch(getListTaskDelete(accessToken));
  }, [user]);

  async function taskDelete(id) {
    dispatch(deleteTask(id, user.id, accessToken));
  }
  async function taskDeleteRestore(id) {
    dispatch(restoreTask(id, user.id, accessToken));
  }

  function showDeleteTask() {
    setVerDeleteTask(true);
  }
  function showNotDeleteTask() {
    setVerDeleteTask(false);
  }
  function updateTask(id, detalle, urlT, idDoc, urlD) {
    dispatch(setIsUpdate(true));
    dispatch(setIdTask(id));
    dispatch(setIdDoc(idDoc));
    dispatch(setTextDescription(detalle));
    dispatch(setTextUrl(urlT));

    if (urlD !== null) {
      dispatch(setTextDoc(urlD.split(/(\\|\/)/g).pop()));
    }
    dispatch(setIdDoc(idDoc));
  }

  const redirectToUrl = (url) => {
    window.open(url, "_blank");
  };

  let listItems = taskList.map((task, index) => (
    <ListItem
      sx={{ m: 0.5, border: 2, borderColor: "primary.light", borderRadius: 2 }}
      key={index}
      secondaryAction={
        <Tooltip title="Eliminar">
          <IconButton
            onClick={() => taskDelete(task.id_tarea)}
            edge="end"
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <ListItemAvatar>
        <Icon>
          <ArrowForwardIosOutlinedIcon fontSize="medium" />
        </Icon>
      </ListItemAvatar>
      <ListItemText
        primary={task.detalle}
        secondary={[
          task.urlT !== "" ? (
            <Typography
              key={index}
              sx={{ textDecoration: "none" }}
              variant="label"
            >
              Referencia:{" "}
              <Link
                component="button"
                onClick={() => redirectToUrl(task.urlT)}
                variant="subtitle2"
              >
                {task.urlT} &nbsp;&nbsp;&nbsp;
              </Link>
            </Typography>
          ) : null,
        ]}
      ></ListItemText>
      <Tooltip title="Ver Archivo">
        <ListItemAvatar>
          {task.urlD !== null ? (
            <IconButton onClick={() => redirectToUrl(task.urlD)}>
              <InsertDriveFileIcon fontSize="small" color="string">
                &nbsp;&nbsp;&nbsp;
              </InsertDriveFileIcon>
            </IconButton>
          ) : null}
        </ListItemAvatar>
      </Tooltip>
      <ListItemAvatar>
        <Tooltip title="Editar">
          <IconButton
            onClick={() =>
              updateTask(
                task.id_tarea,
                task.detalle,
                task.urlT,
                task.id_documento,
                task.urlD
              )
            }
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </ListItemAvatar>
    </ListItem>
  ));
  function BtnDeleteTask() {
    if (verDeleteTask) {
      return (
        <Button
          onClick={showNotDeleteTask}
          sx={{ ml: 4 }}
          variant="outlined"
          size="small"
          startIcon={<DoDisturbIcon />}
        >
          Ocultar Eliminados
        </Button>
      );
    } else {
      return (
        <Button
          onClick={showDeleteTask}
          color="error"
          sx={{ ml: 4 }}
          variant="outlined"
          size="small"
          startIcon={<DoDisturbIcon />}
        >
          Ver Eliminados
        </Button>
      );
    }
  }

  let listItemsDelete = taskListDelete.map((task, index) => (
    <ListItem
      key={index}
      sx={{ m: 0.5, border: 2, borderColor: "error.delete", borderRadius: 2 }}
      secondaryAction={
        <Tooltip title="Restaurar">
          <IconButton
            onClick={() => taskDeleteRestore(task.id_tarea)}
            edge="end"
            aria-label="delete"
          >
            <RestoreFromTrashIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <ListItemAvatar>
        <Icon>
          <CloseIcon fontSize="medium" color="error" />
        </Icon>
      </ListItemAvatar>
      <ListItemText
        primary={task.detalle}
        secondary={[
          task.url !== "" ? (
            <Typography
              key={index}
              sx={{ textDecoration: "none" }}
              variant="label"
            >
              Referencia:{" "}
              <Link key={index} disabled variant="subtitle2" color="inherit">
                {task.url} &nbsp;&nbsp;&nbsp;
              </Link>
            </Typography>
          ) : null,
        ]}
      ></ListItemText>
    </ListItem>
  ));

  return (
    <Paper
      sx={{ flexGrow: 1, Width: "100%", bgcolor: "background.paper", p: 2 }}
    >
      <Typography sx={{ mb: 2 }} variant="h6" component="div">
        Tareas realizadas el dia de hoy
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Demo sx={{ m: 1, padding: "10px" }}>
            <List
              sx={{
                maxWidth: "100%",
              }}
            >
              {listItems}
              {verDeleteTask ? listItemsDelete : null}
            </List>
          </Demo>
        </Grid>

        <BtnDeleteTask />
      </Grid>
    </Paper>
  );
}
