import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Button from "@mui/material/Button";
import ReportTable from "../components/ReportTableComponent";

import { useDispatch, useSelector } from "react-redux";
import { getReportList, changePdfReport } from "../store/ReportSlice";
import { TextField } from "@mui/material";

const ReportPage = () => {
  const [dateI, setDateI] = useState(new Date("2022-01-18T21:11:54"));
  const [dateO, setDateO] = useState(new Date("2022-01-18T21:11:54"));

  const accessToken = useSelector((state) => state.login.accessToken);

  const reportList = useSelector((state) => state.report.reportList);
  const pdfReport = useSelector((state) => state.report.pdfReport);
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const setDateS = (event) => {
    setDateI(event.target.value);
    dispatch(getReportList(event.target.value, dateO, accessToken));
  };

  const setDateE = (event) => {
    setDateO(event.target.value);
    dispatch(getReportList(dateI, event.target.value, accessToken));
  };

  const generatePDF = () => {
    if (dateI <= dateO && reportList.length) {
      const report = document.getElementById("report");
      html2canvas(report).then(function (canvas) {
        const imgDiv = canvas.toDataURL("image/png");
        const pdfReport = new jsPDF("p", "pt", "letter");
        pdfReport.addImage(imgDiv, "PNG", 45, 30);
        pdfReport.save("download.pdf");
      });
      return;
    }
    dispatch(changePdfReport(true));
  };

  return (
    <div>
      <div
        id="report"
        style={{ width: 700, marginLeft: "auto", marginRight: "auto" }}
      >
        <div style={{ textAlign: "center" }}>
          <h2>Reporte de horas trabajadas</h2>
        </div>

        <div style={{ width: 700, marginLeft: "auto", marginRight: "auto" }}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              {" "}
              <p>Fecha Inicio</p>
              <TextField type="date" onChange={setDateS} value={dateI} />
            </div>
            <div>
              <p>Fecha final</p>
              <TextField type="date" onChange={setDateE} value={dateO} />
              {dateI > dateO ? (
                <p style={{ color: "red", fontSize: "12px" }}>Fecha invalida</p>
              ) : null}
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <ReportTable register={reportList} />
        </div>
      </div>

      <br />

      <div
        style={{
          width: 200,
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "40px",
        }}
      >
        <Button variant="outlined" onClick={generatePDF} style={{ width: 200 }}>
          Generar PDF
        </Button>
        {pdfReport ? (
          <p style={{ color: "red", fontSize: "12px" }}>
            No se puede generar el archivo PDF vacio
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default ReportPage;
