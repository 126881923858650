import { createSlice } from "@reduxjs/toolkit";
import API from "../conection";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || {},
  status: localStorage.getItem("sesion_status") || "offline",
  rolUser: '',
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      localStorage.setItem("user", JSON.stringify(payload.usuario));
      state.user = JSON.parse(localStorage.getItem("user"));
      

    },
    setRolUser:(state, action)=>{
      state.rolUser = action.payload.rol
    },
    setUserStatus: (state, { payload }) => {
      localStorage.setItem("sesion_status", payload);
      state.status = localStorage.getItem("sesion_status");
    },
    clearUser: () => {
      localStorage.clear();
    },
  },
});

export const getUsuarioAsync = (accessToken) => async (dispatch) => {
  try {
    const response = await API.get(`/usuario/get-usuario`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.data.message === "offline") {
      dispatch(clearUser());
    } else {
      dispatch(setUser(response.data));
      dispatch(setUserStatus("online"));
      dispatch(setRolUser(response.data))
    }
  } catch (err) {
    throw new Error(err);
  }
};
export const { setUser, setUserStatus, clearUser, setRolUser } = userSlice.actions;
export default userSlice.reducer;
