import { createSlice } from "@reduxjs/toolkit";
import API from "../conection";

const initialState = {
  userTaskList: [],
  usersList: [],
};

const reportTaskSlice = createSlice({
  name: "userTask",
  initialState,
  reducers: {
    setUserTaskList: (state, action) => {
      state.userTaskList = action.payload;
    },
    setUserList: (state, action) => {
      state.usersList = action.payload;
    },
  },
});

export const getUserTaskList = (id, accessToken) => async (dispatch) => {
  try {
    const taskList = await API.get(`/tarea/user-task-list?id=${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(setUserTaskList(taskList.data));
    return taskList;
  } catch (e) {
    console.log(e);
  }
};

export const getUserList = (accessToken) => async (dispatch) => {
  try {
    const userList = await API.get(`/tarea/get-user-list`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(setUserList(userList.data));
    return userList;
  } catch (e) {
    console.log(e);
  }
};

export const { setUserTaskList, setUserList } = reportTaskSlice.actions;
export default reportTaskSlice.reducer;
