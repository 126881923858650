import React from "react";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3945c3',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        hover: '#000000 !important'
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },

}));

const ReportTable = (props) => {

    const { register } = props

    return (
        <div style={{ width: 700, marginLeft: 'auto', marginRight: 'auto' }}>
            <TableContainer >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <StyledTableCell >Nro</StyledTableCell>
                            <StyledTableCell >Nombre</StyledTableCell>
                            <StyledTableCell >Horas</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {register.map((row, index) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell >{index + 1}</StyledTableCell>

                                <StyledTableCell component="th" scope="row">
                                    {row.fullname}
                                </StyledTableCell>
                                <StyledTableCell >{row.hours}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );

}
export default ReportTable;
