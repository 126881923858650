/* eslint-disable react-hooks/exhaustive-deps */
import "./TaskPage.css";
import React, { useEffect } from "react";
import FormTask from "../components/FormTask";
import Task from "../components/Task";
import { useDispatch, useSelector } from "react-redux";
import { getListTask } from "../store/TaskSlice";
import { getUsuarioAsync } from "../store/UserSlice";
import { Box } from "@mui/system";
import { Container } from "@mui/material";

function TaskPage() {
  const taskList = useSelector((state) => state.tasks.taskList);
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.login.accessToken);
  useEffect(() => {
    const res = dispatch(getUsuarioAsync(accessToken));
    res.then(() => dispatch(getListTask(accessToken)));
  }, []);

  return (
    <Box>
      <Container className="Content-body" maxWidth="xl">
        <div className="Content-box">
          <div>
            <h2>Registro de tareas</h2>
            <FormTask />
          </div>
          <br />
          <Task tasks={taskList} />
        </div>
      </Container>
    </Box>
  );
}

export default TaskPage;
