import { configureStore } from "@reduxjs/toolkit";
import TaskSlice from "./TaskSlice";
import ReportSlice from "./ReportSlice";
import loginReducer from "./LoginSlice";
import UserSlice from "./UserSlice";
import ReportTaskSlice from "./ReportTasksSlice"

export default configureStore({
  reducer: {
    login: loginReducer,
    user: UserSlice,
    tasks:TaskSlice,
    userTask: ReportTaskSlice,
    report:ReportSlice
  },
});
