/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import UserTaskList from "../components/UserTaskListComponent";

import { useDispatch, useSelector } from "react-redux";
import { getUserTaskList, getUserList } from "../store/ReportTasksSlice";

const ReportTaskPage = () => {
  const userList = useSelector((state) => state.userTask.usersList);
  const accessToken = useSelector((state) => state.login.accessToken);
  const user = useSelector((state) => state.user.user);
  const rolUser = useSelector((state) => state.user.rolUser);

  const dispatch = useDispatch();
  const isAdmin = rolUser === "admin";

  useEffect(() => {
    isAdmin
      ? dispatch(getUserList(accessToken))
      : dispatch(getUserTaskList(user.id, accessToken));
  }, []);

  const searchTasks = (selected) => {
    if (selected !== null) {
      dispatch(getUserTaskList(selected.id, accessToken));
    } else {
      dispatch(getUserTaskList(0, accessToken));
    }
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2>Historial de tareas</h2>
      </div>
      <div style={{ width: 700, marginLeft: "auto", marginRight: "auto" }}>
        <br />
        <br />
        {isAdmin ? (
          <div style={{ width: 700, display: "flex" }}>
            <p style={{ fontSize: 20 }}>Seleccionar funcionario</p>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={userList}
              sx={{ width: 400, marginLeft: "auto", marginRight: "auto" }}
              renderInput={(params) => (
                <TextField {...params} label="Funcionario" />
              )}
              getOptionLabel={(option) => option.full_name}
              onChange={(e, selected) => searchTasks(selected)}
            />
          </div>
        ) : null}

        <div style={{ width: 700, marginLeft: "auto", marginRight: "auto" }}>
          <UserTaskList />
        </div>
        <br />
      </div>
    </div>
  );
};

export default ReportTaskPage;
