import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { grey, orange } from "@mui/material/colors";
import { GoogleLogout } from "react-google-login";
import { Logout, Settings } from "@mui/icons-material";
import { postLogoutAsync } from "../store/LoginSlice";
import { Divider } from "@mui/material";

const NavBar = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { accessToken } = useSelector((state) => state.login);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const rolUser = useSelector((state) => state.user.rolUser);
  const isAdmin = rolUser === "admin";

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const navigate = useNavigate();

  const onSignoutSuccess = () => {
    navigate("/", { replace: true });
    /* dispatch(postLogoutAsync(accessToken)); */
  };
  const log = (renderProps) => async () => {
    const res = await dispatch(postLogoutAsync(accessToken));
    if (res) {
      renderProps.onClick();
    }
  };
  const ItemNav = (props) => {
    const router = useLocation();
    const active = props.href ? router.pathname === props.href : false;
    return (
      <NavLink to={props.href} style={{ textDecoration: "none" }}>
        <Button
          disableRipple
          sx={{
            my: 2,
            color: active ? orange[200] : grey[100],
            fontWeight: "bold",
          }}
        >
          {props.text}
        </Button>
      </NavLink>
    );
  };
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="div"
            noWrap
            sx={{
              mr: 2,
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            <Typography variant="h6" noWrap component="div">
              <ItemNav href={"/"} text="Tareas UMSS"></ItemNav>
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                component={Link}
                to="/reg-task"
                onClick={handleCloseNavMenu}
              >
                <Typography textAlign="center">Tareas</Typography>
              </MenuItem>
              {isAdmin ? (
                <MenuItem
                  component={Link}
                  to="/report"
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign="center">Reporte</Typography>
                </MenuItem>
              ) : null}
              <MenuItem
                component={Link}
                to="/historial"
                onClick={handleCloseNavMenu}
              >
                <Typography textAlign="center">Historial</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box
            component="div"
            noWrap
            sx={{
              mr: 2,
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <Typography variant="h6" noWrap component="div">
              <ItemNav href={"/"} text="Tareas UMSS"></ItemNav>
            </Typography>
          </Box>

          <Box sx={{ mr: 2, display: { xs: "none", md: "flex" } }}>
            <ItemNav href={"/reg-task"} text="tareas"></ItemNav>
            {isAdmin ? (
              <ItemNav href={"/report"} text="reporte"></ItemNav>
            ) : null}
            <ItemNav href={"/user-task-list"} text="historial"></ItemNav>
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255, .90)",
                backdropFilter: "blur(10px)",
                display: "flex",
                p: 0.5,
                px: user.email ? 1 : 0.5,
                borderRadius: "10px",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Box
                sx={{
                  color: "text.primary",
                  mr: user.email ? 1 : 0,
                  display: { xs: "none", sm: "block" },
                }}
              >
                <Typography
                  sx={{ fontSize: 14, lineHeight: 1, fontWeight: "bold" }}
                >
                  {user.full_name}
                </Typography>
                <Typography variant="body2">{user.email}</Typography>
              </Box>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={user.full_name} src={user.picture} />
                </IconButton>
              </Tooltip>
            </Box>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box
                sx={{
                  color: "text.primary",
                  p: 2,
                  display: { xs: "block", sm: "none" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    lineHeight: 1,
                    fontWeight: "bold",
                    textTransform: "lowercase",
                  }}
                >
                  {user.full_name}
                </Typography>
                <Typography variant="body2">{user.email}</Typography>
              </Box>
              <Divider />
              <MenuItem component={Link} to="/resetPassword">
                <Settings sx={{ color: "primary.main" }}></Settings>
                <Typography
                  variant="body2"
                  sx={{ ml: 1, color: "primary.main" }}
                >
                  Cambiar contraseña
                </Typography>
              </MenuItem>
              <MenuItem /* onClick={() => dispatch(postLogoutAsync())} */>
                <Logout sx={{ color: "primary.main" }}></Logout>
                <GoogleLogout
                  clientId={clientId}
                  buttonText="Sign Out"
                  onLogoutSuccess={onSignoutSuccess}
                  render={(renderProps) => (
                    <Button
                      onClick={log(renderProps)}
                      disabled={renderProps.disabled}
                      fullWidth
                      sx={{ textTransform: "none" }}
                      /* 												sx={{
													background: grey[200],
													color: grey[800],
													
												}} */

                      size="large"
                    >
                      Cerrar Sesion
                    </Button>
                  )}
                />
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
