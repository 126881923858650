import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import API from "../conection";

function FormResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoadingForgot, setIsLoadingForgot] = useState(false);
  const [sendFailed, setSendFailed] = useState(false);
  const { accessToken } = useSelector((state) => state.login);
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const [successReset, setSuccessReset] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Contraseña es requerido")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.)[A-Za-z\d]{8,}$/,
          "Debe contener almenos 8 Caracteres, 1 mayuscula, 1 minuscula, 1 numero"
        ),
      confirm: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Las contraseñas deben ser iguales"
      ),
    }),
    onSubmit: (values) => {
      setIsLoadingForgot(true);

      // const newPassword = values.password;
      const resetPassword = async () => {
        try {
          const { data } = await API.post("/usuario/reset-password", values, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          console.log(data);
          setIsLoadingForgot(false);
          setSendFailed(false);
          setSuccessReset(true);
        } catch (err) {
          setIsLoadingForgot(false);
          setSuccessReset(false);
          setSendFailed(true);
          throw new Error(err);
        }
      };
      resetPassword();
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            margin="normal"
            label="Contraseña nueva"
            variant="outlined"
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...getFieldProps("password")}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            sx={{ borderRadius: 2 }}
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Confirmar contraseña"
            {...getFieldProps("confirm")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.confirm && errors.confirm)}
            helperText={touched.confirm && errors.confirm}
          />
          <Box sx={{ py: 2, position: "relative" }}>
            <Button
              color="primary"
              /* 						disabled={formik.isSubmitting} */
              fullWidth
              size="large"
              type="submit"
              disabled={isLoadingForgot}
              variant="contained"
            >
              Cambiar contraseña
            </Button>
            {isLoadingForgot && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
          <Box sx={{ mt: 2 }}>
            {successReset && (
              <Typography sx={{ color: green[400], fontWeight: "bold" }}>
                Se cambio la contraseña exitosamente
              </Typography>
            )}
            {sendFailed && (
              <Typography sx={{ color: red[400], fontWeight: "bold" }}>
                Algo salio mal, vuelva a intentarlo.
              </Typography>
            )}
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default FormResetPassword;
